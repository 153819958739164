import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Blog extends Component {
  render() {
    return (
      <div class="blog">
        <div class="container">
          <div class="row mt-5 blog-row">
            <div class="col-md-6 col-sm-12">
              <div class="blog-singe no-margin row">
                <div class="col-sm-5 blog-img-tab">
                  <img src="assets/images/blog/b1.png" alt="" />
                </div>
                <div class="col-sm-7 blog-content-tab">
                  <h2>Curabit finibus dui sem.</h2>

                  <p class="blog-desic">
                    Lorem Ipsum, type lorem then press the shortcut. The default
                    keyboard shortcut is the same keyboard shortcut is the{" "}
                  </p>
                  <Link to="/blogs">
                    Read More <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="blog-singe no-margin row">
                <div class="col-sm-5 blog-img-tab">
                  <img src="assets/images/blog/b2.png" alt="" />
                </div>
                <div class="col-sm-7 blog-content-tab">
                  <h2>Excepteur sint occaecat</h2>

                  <p class="blog-desic">
                    Lorem Ipsum, type lorem then press the shortcut. The default
                    keyboard shortcut is the same keyboard shortcut is the{" "}
                  </p>
                  <Link to="/blogs">
                    Read More <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
