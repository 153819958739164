import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import LandingPage from "./components/LandingPage";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Blogs from "./components/Blogs";
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/blogs" component={Blogs} />
      </Switch>
    </Router>
  );
}

export default App;
